<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import SearchBar from "./SearchBar.svelte";
  import { slide, fly } from "svelte/transition";
  import { onMount } from "svelte";

  export let isOpen: boolean = false;
  export let nav_items: NavigationMenuItem[];
  export let request_store_key: StoresKey;
  export let supported_languages: string[];

  const { site_page, reverse_router, __, T, locale } =
    getStoresContext(request_store_key);

  let body: HTMLBodyElement;
  onMount(() => {
    body = document.getElementsByTagName("body")[0];
  });

  $: {
    if (isOpen && body) {
      body.classList.add("overflow-hidden");
    } else if (!isOpen && body) {
      body.classList.remove("overflow-hidden");
    }
  }
  $: t = $T;
</script>

{#if isOpen && $locale}
  <div class="dropdown" transition:slide|local>
    <div class="container-fluid container-fluid-max-xl px-lg-5">
      <div class="row">
        <div class="col-12" transition:fly={{ y: -200, duration: 800 }}>
          <SearchBar {request_store_key} {supported_languages} />
          <div class="my-4 my-lg-5">
            {#each nav_items as item}
              <a
                class="main-link d-lg-inline d-block mb-lg-4 mb-3"
                href={item.link ? $reverse_router.routePage(item.link) : item.url}
                class:active={item.link == $site_page.friendlyUrlPath}
                target={item.link ? "_self" : "_blank"}
              >
                {$__(item.name_i18n)}
              </a>
            {/each}
          </div>

          <!--  Subpages go here -->
          <div class="sub-links mb-md-5 mb-9">
            <span style="opacity:0.5; font-size:17px">{t`more about us`}:</span
            ><br />
            {#each nav_items as item}
              {#if item.navigationMenuItems?.length}
                {#each item.navigationMenuItems.filter((item) => !item.excluded_locales?.some((x) => x == $locale)) as sub_item}
                  {#if sub_item.type == "url"}
                    {#if sub_item.availableLanguages.includes($locale)}
                      <a
                        class="sub-link"
                        class:active={sub_item.link ==
                          $site_page.friendlyUrlPath}
                        href={sub_item.url}
                        target="_blank">
                        {$__(sub_item.name_i18n)}
                      </a>
                    {/if}
                  {:else}
                    <a
                      class="sub-link"
                      class:active={sub_item.link == $site_page.friendlyUrlPath}
                      href={$reverse_router.routePage(sub_item.link)}>
                      {$__(sub_item.name_i18n)}
                    </a>
                  {/if}
                {/each}
              {/if}
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .sub-links {
    line-height: 2.5em;
  }

  .sub-link,
  .main-link {
    margin-right: 1rem;
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    display: block;
    @media screen and (min-width: 992px) {
      margin-right: 2rem;
      display: inline-block;
    }
  }
  .sub-link {
    font-size: 16px;
  }

  .dropdown {
    position: fixed;
    top: 63px;
    overflow: hidden;
    color: #fff;
    overflow: auto;
    height: calc(100vh - 64px);
    width: 100vw;
    z-index: 2;
    background-color: var(--brandColor1);
    @media only screen and (min-width: 768px) {
      height: calc(100vh - 79px);
      top: 79px;
    }
  }

  @media only screen and (min-width: 992px) {
    .main-link {
      font-size: 25px;
    }
    .sub-link {
      font-size: 18px;
    }
  }
</style>
