<!-- @format -->
<script lang="ts">
  import DropdownMenu from "./DropdownMenu.svelte";
  import Logo from "../Logo.svelte";
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";

  export let scrollingPosition: number = 0;
  export let request_store_key: StoresKey;

  let isOpen: boolean = false;

  const { locale, nav_menus, site_page, reverse_router, i18nOf, T, site } =
    getStoresContext(request_store_key);

  let rest: NavigationMenuItem[];
  $: logo = $site.logo;
  $: supportedLanguages = $site.supportedLanguages;
  $: openingHoursText = $site.openingHoursText;
  $: current = $site_page?.friendlyUrlPath;
  $: primary = $nav_menus.find((nav) => nav.navigationType == "Primary");
  $: [, ...rest] = (primary?.navigationMenuItems || []).filter(
    (item) => !item.excluded_locales?.some((x) => x == $locale)
  );

  $: opening_hour_page_href = $reverse_router.routePurpose("opening hours");
  $: t = $T;

  let anchor: HTMLElement;
  function onOpeningHoursClicked() {
    anchor?.click();
  }
</script>

<svelte:window bind:scrollY={scrollingPosition} />

<header
  class:shadow-sm={scrollingPosition > 50}
  class:background={isOpen}
  class="fixed-top">
  <nav class="h-100 d-lg-flex align-items-center">
    <div class="container-fluid container-fluid-max-xl d-none d-lg-block">
      <div class="row">
        <div class="col-xl-5 col-lg-6">
          <div class="d-flex align-items-center">
            {#if isOpen}
              <Logo {request_store_key} url={logo} color="white" />
            {:else}
              <Logo {request_store_key} url={logo} color="black" />
              <div 
                class="d-flex align-items-center opening-hours-wrapper"
                on:click={onOpeningHoursClicked}
                on:keydown={onOpeningHoursClicked}
              >
                <img
                  src="/theme/citycon2023/images/clock.png"
                  width="30"
                  alt="clock"
                  class="d-inline-block ml-xl-2 mr-2 opening-hours-clock" />
                <div>
                  {#if openingHoursText}
                    <p class="mb-0 opening-hours">{openingHoursText}</p>
                  {/if}
                  <a href={opening_hour_page_href} class="opening-hours-link" bind:this={anchor}>
                    {t`opening hours`}
                  </a>
                </div>
              </div>
            {/if}
          </div>
        </div>
        <div class="col-xl-7 col-lg-6">
          <div class="nav-items">
            <ul class="p-0" class:d-none={isOpen}>
              {#each rest as item}
                <li class="nav-item">
                  <a
                    href={item.link ? $reverse_router.routePage(item.link) : item.url}
                    class:active={item.link == current}
                    target={item.link ? "_self" : "_blank"}
                  >
                    {i18nOf(item.name_i18n)}
                  </a>
                </li>
              {/each}
            </ul>
            <button
              class="button-icon"
              class:crossed={isOpen}
              on:click={() => {
                isOpen = !isOpen;
              }}>
              {#if isOpen}
                <span class="close-menu">{t`close menu`}</span>
              {/if}
              <span class="button" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-lg-none h-100">
      <div class="col-12 d-flex nav-mobile h-100 px-3">
        <div class="mr-auto">
          {#if isOpen}
            <Logo {request_store_key} url={logo} color="white" />
          {:else}
            <Logo {request_store_key} url={logo} color="black" />
          {/if}
        </div>
        <a href={opening_hour_page_href} class:d-none={isOpen}>
          <img
            src="/theme/citycon2023/images/clock.png"
            alt="clock"
            width="32"
            class="mr-3" />
        </a>
        <button
          class="button-icon"
          class:crossed={isOpen}
          on:click={() => {
            isOpen = !isOpen;
          }}>
          <span class="button" />
        </button>
      </div>
    </div>
    <DropdownMenu
      nav_items={rest}
      supported_languages={supportedLanguages}
      {isOpen}
      on:closeDropdown={() => {
        isOpen = false;
      }}
      {request_store_key} />
  </nav>
</header>
<div class="gap" />

<style lang="scss">
  .gap {
    height: 64px;
    @media screen and (min-width: 768px) {
      height: 96px;
    }
  }
  .nav-item {
    margin-right: 1rem;

    @media screen and (min-width: 992px) {
      margin-right: 1rem;
      white-space: nowrap;
    }

    @media screen and (min-width: 1280px) {
      margin-right: 1.5rem;
    }

    @media screen and (min-width: 1440px) {
      margin-right: 2rem;
    }

    @media screen and (min-width: 1920px) {
      margin-right: 64px;
    }
  }

  header {
    transition: border 0.3s;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    overflow: hidden;
  }

  li {
    list-style: none;
    overflow: hidden;
  }
  .close-menu {
    position: absolute;
    right: 100%;
    width: 142px;
    font-weight: bold;
  }
  .crossed span::before {
    transform: rotate(45deg);
    background-color: #fff;
  }
  .crossed span::after {
    transform: rotate(-45deg);
    background-color: #fff;
  }
  .crossed span::before,
  .crossed span::after {
    top: 0;
  }
  .crossed span {
    background: none;
  }
  .button-icon {
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
    align-items: center;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background: none;
    border: 0;
    padding: 0;
  }
  .button-icon:hover {
    transform: scale(1.06);
  }

  .button {
    background: #000;
    width: 24px;
    height: 2px;
    border: 0;
    display: block;
    position: relative;
    transition: all 0.3s;
  }
  .button::after,
  .button::before {
    content: "";
    background: #000;
    width: 24px;
    height: 2px;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  .button::after {
    top: 9px;
  }
  .button::before {
    top: -9px;
  }
  .background {
    background-color: var(--brandColor1);
  }
  .opening-hours-wrapper {
    cursor: pointer;
    color: #000;
  }
  .opening-hours {
    font-size: 16px;
    font-weight: bold;
  }
  .opening-hours-link {
    font-size: 12px;
    text-decoration: none;
    display: block;
  }
  .opening-hours:hover, .opening-hours-link:hover {
    color: #737373;
  }
  .opening-hours-clock:hover {
    filter: invert(35%);
  }
  header li {
    display: inline;
    margin-right: 36px;
    @media screen and (min-width: 1280px) {
      margin-right: 48px;
    }
  }

  header li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 6px;
    letter-spacing: -0.02em;

    @media screen and (min-width: 1280px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 20px;
    }
  }
  header li a:hover {
    border-bottom: 2px solid var(--brandColor3);
  }

  header ul {
    margin: 0;

    @media screen and (min-width: 992px) {
      height: 30.5px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
    }
  }

  header {
    color: #000;
    background-color: rgb(255, 255, 255);
    height: 64px;
    @media screen and (min-width: 768px) {
      height: 96px;
    }
  }

  .nav-items {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
  }

  .nav-mobile {
    justify-content: space-between;
    align-items: center;
  }

  .active {
    border-bottom: 2px solid var(--brandColor3);
  }
</style>
