<!-- @format -->
<script lang="ts">
  import { type Alternate } from "~/metadata";
  import { ReverseRouter } from "~/reverse-router";
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  export let request_store_key: StoresKey;
  export let supported_languages: string[];
  const { T, locale, reverse_router, alternate } =
    getStoresContext(request_store_key);

  $: t = $T;
  $: search_href = $reverse_router.routePurpose("search");

  let value: string = "";

  function hrefFor (
    locale: string, 
    alternate: readonly Alternate[], 
    reverse_router: Readonly<ReverseRouter>
  ) {
    const alt = alternate.find(x => x.locale == locale);
    return alt ? alt.href : reverse_router.findPurposeRoute('home page', locale);
  }

  function search() {
    window.location.href = search_href + "?search=" + value;
  }

  const languages: Record<string,string> = {
    "fi-FI": "FI",
    "en-US": "EN",
    "sv-SE": "SV",
    "ru-RU": "RU",
    "et-EE": "ET",
    "nb-NO": "NO",
    "da-DK": "DA",
  };
</script>

<div class="search-bar">
  <div class="row pt-5">
    <div class="col-12 col-sm-7 col-xl-10 pe-lg-5 mb-4">
      <div style="position: relative;">
        <input
          class="form-control select-caterogy"
          name="search"
          placeholder={t`search`}
          bind:value
          on:change={search} />
        <img
          src="/theme/citycon2023/images/Search.png"
          class="icon"
          alt="search"
          width="32" />
      </div>
    </div>
    {#if supported_languages.length > 1}
      <div class="col-xl-2 col-12 col-sm-5">
        <div class="languages">
          {#each supported_languages as _locale}
            {#if _locale == $locale}
              <span class="lang active" title={languages[_locale]}>{languages[_locale]}</span>
            {:else}

              <a class="lang" title={languages[_locale]} 
                href={hrefFor(_locale, $alternate, $reverse_router)}
                >{languages[_locale]}</a>
            {/if}
          {/each}
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .languages {
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: 1rem;
  }
  .active {
    font-weight: bold;
    border-bottom: 1px solid !important;
  }
  .lang {
    background-color: transparent;
    border: none;
    color: var(--color-white);
    font-size: 18px;
    margin-right: 0.5rem;
    position: relative;
    padding: 2px 8px 2px;
    @media screen and (min-width: 768px) {
      font-size: 22px;
      margin-right: 0.8rem;
    }
  }
  .lang::after {
    content: attr(title);
    visibility: hidden;
    height: 0;
    display: block;
    border-bottom: 1px solid !important;
    font-weight: bold;
  }
  .lang:hover {
    font-weight: bold;
    border-bottom: 1px solid !important;
    text-decoration: none;
  }

  .search-bar {
    bottom: 2.5rem;
    width: 100%;
    left: 0;
  }
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    filter: invert(100%);
  }
  .select-caterogy {
    border: none;
    border-bottom: 1px solid #fff;
    background: none;
    color: #fff;
    border-radius: 0;
    padding-left: 0;
  }

</style>
